.subsite {

  &__header {
    border-bottom: 1px solid $color-main;
    width: 100%;
    height: 35vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    background-image: url("../../images/default-foto.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom -5vw center;

    @include mq($until: lg) {
      height: 45vw;
    }

    div {
      background-color: rgba(255,255,255, .8);
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      align-content: center;

      h1 {
        margin: 0;
      }
    }
  }
}