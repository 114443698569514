.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid $color-main;
    z-index: 9999999;
    padding-top: 10px;
    padding-bottom: 10px;

    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__menuItem{
        margin-left: 15px;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        color: rgba(125, 125, 125, 1);
        @include mq($until: md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            transition: all .3s;
        }

        &:active, &--active, &--open{
            color: $color-extra;
        }

        &:hover {
            @include mq($until: md) {
                color: white;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
    &__logo {
        max-width: 70%;

        @include mq($until: xl) {
            max-width: 30%;
        }
    }
}