.section1, .section2, .section3, .section4 {
  height: 100vh;

  @include mq($until: lg) {
    height: auto;
  }
}

.section1 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;

  @include mq($until: lg) {
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
  }
}

.section2 {
  width: 100%;
  background-color: rgb(255,255,255);
  display: flex;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;

  p {
    line-height: 1.7;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 25vw;
    column-gap: 5vw;

    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
  }

  &__img-container {
    background-image: url("../../images/about_us_photo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 25vw;
    align-self: center;

    @include mq($until: md) {
      display: none;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1rem;
    }

    h2 {
      font-weight: 600;
    }
  }

  .button {
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: auto;
  }
}

.section3 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;

  @include mq($until: lg) {
    flex-direction: column;
    justify-content: center;
  }

  &__image-container {
    height: 80%;
    display: flex;
    align-items: flex-end;

    img {
      width: auto;
      max-height: 100%;
      margin-bottom: -10px;
    }
  }

  .header-box {
    h2 {
      margin-bottom: 10px;
    }

    li {
      margin-bottom: 30px;

      @include mq($until: md) {
        margin-bottom: 15px;
      }
    }
  }

  &__list {
    font-family: 'Cinzel';

    a {
      color: $color-main;
      transition: .3s;

      &:hover {
        color: $link-color;
      }
    }

    @include mq($until: md) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq($until: xs) {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
    }

    .list__first {
      grid-column: 2/3;

      @include mq($until: xs) {
        grid-column: unset;
      }
    }

    .list__second {
      grid-column: 1/2;
      grid-row: 1/2;

      @include mq($until: xs) {
        grid-column: unset;
      }
    }
  }
}

.section4 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;

  &__text-container {
    h2,p {
      margin-bottom: 50px;

      @include mq($until: xl) {
        margin-bottom: 35px;
      }
    }
  }

  &__content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;

    @include mq($until: lg) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1.5fr;
    }

    @include mq($until: xs) {
      grid-template-rows: 1fr 1fr;
    }

  }
  &__map-container {
    border: 1px solid $color-main;
    padding: 30px;
    filter: grayscale(100%);

    @include mq($until: xs) {
      padding: 5px;
    }
  }

  &__social-media {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
  }

  .social-media__link {
    filter: grayscale(100%) brightness(0);
    transition: all .3s;
    display: inline-block;
    margin-left: 10px;
    opacity: .6;

    &:hover {
      filter: grayscale(0) brightness(100%);
      opacity: 1;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 40px;

    @include mq($until: xl) {
      grid-row-gap: 20px;
    }

    @include mq($until: lg) {
      grid-column-gap: 15px;
      grid-template-columns: repeat(2, min-content 1fr);
    }

    @include mq($until: sm) {
      grid-template-columns:  min-content 1fr;
    }
  }

  &__text {
    line-height: 1.8;

    &--email {
      @include mq($until: lg) {
        grid-column: 4/5;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }

  &__icon {
    width: 26px;

    img {
      width: 100%;
    }

    &--email {
      @include mq($until: lg) {
        grid-column: 3/4;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }
}

.section__background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eeeeee;

  @include mq($until: lg) {
    position: static;
    height: 55vw;
    width: 100%;
    background-position: center center;
  }

  @include mq($until: xs) {
    height: 75vw;
  }

  &--section1 {
    background-image: url("../../images/top-foto.jpg");
    background-position: right -14vw center;

    @include mq($until: lg) {
      background-position: center center;
    }

    @include mq($until: lg) {
      background-position: center center;
    }
  }

  &--section3 {
    background-image: url('../../images/familly-bg.jpg');
    align-items: flex-end;
    display: flex;
    background-position: center center;
    padding-left: 5vw;
    overflow: hidden;

    @include mq($until: lg) {
      justify-content: center;
      background-position: center center;
    }
  }
}