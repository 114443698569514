.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid $color-main;
    padding-top: 15px;
    padding-bottom: 15px;

    @include mq($until: md) {
        flex-direction: column;
        justify-content: center;

        & > * {
            margin-bottom: 10px;
        }
    }

    a {
        display: flex;
        align-items: center;

        img {
            margin-left: 10px;
        }
    }
}
.lighten {
    opacity: .8;
}