.button {
  color: inherit;
  padding: 15px 20px;
  font-family: 'Cinzel';
  transition: all .3s;

  &--gold {
    border: 1px solid $color-main;
    color: $color-main;

    &:hover {
      background-color: $color-main;
      border-color: white;
      color: white;
    }
  }
}