// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'Montserrat';
     font-weight: 300;
     src: url('../../fonts/Montserrat-Light.ttf');
 }
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('../../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 400;
  src: url('../../fonts/Cinzel-Regular.ttf');
}

@font-face {
  font-family: 'Cinzel';
  font-weight: 600;
  src: url('../../fonts/Cinzel-Bold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('../../fonts/Poppins-Light.ttf');
}