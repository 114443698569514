*, *::before, *::after {
    box-sizing: border-box;
}
.site-wrapper{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    overflow: hidden;
}
.padding-container{
    padding: 0 260px;
    @include mq($until: xl){padding: 0 10vw;}
    @include mq($until: md) {padding: 0 30px;}
    @include mq($until: xs) {padding: 0 15px;}
}
.padding-container--right {
    padding-right: 260px;
    @include mq($until: xl){padding-right: 10vw;}
    @include mq($until: md) {padding-right: 30px;}
    @include mq($until: xs) {padding-right: 15px;}
}
.padding-container--header{
    padding: 0 200px;
    @include mq($until: xl){padding: 0 10vw;}
    @include mq($until: md) {padding: 0 30px;}
    @include mq($until: xs) {padding: 0 15px;}
}

footer{
    margin-top: auto;
}
.no-gutters{
    & > .col-left{
        padding-right: 30px;
        @include mq($until: md) {padding-right: 0;}
    }
    & > .col-right{
        padding-left: 30px;
        @include mq($until: md) {padding-left: 0;}
    }
}
.col-left, .col-right, .col-inside{
    margin-bottom: 30px;
}